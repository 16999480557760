import { Collapse } from 'bootstrap'
import $ from 'jquery'

// Use this so the links inside the dropdown menu are clickable
$('.evidence-menu').on('click', function (e) {
    $(this).parent().is('.show') && e.stopPropagation()
 })

$('.evidence-menu .close').on('click', function (e) {
    $(this).parent().removeClass('show')
})

var collapaseElementList = [].slice.call(document.querySelectorAll('.collapse'))
collapaseElementList.map(function(el) {
    new Collapse(el, {
        toggle: false
    })
})
